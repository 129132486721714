body {
    background-color: #FECD72;
    margin: 0px;
    padding: 6px;
    box-sizing: border-box;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.app {
    text-align: center;
}

@media (min-width: 900px) {
    .app {
        margin: 35px 15px;
    }
}

@media (min-width: 1200px) {
    body {
        margin: 0 10px;
    }

    .app {
        max-width: 1140px;
        margin: 35px auto;
    }
}

.header {
    background-color: #E3EFF2;
    margin: 0 0 0px;
    padding: 2px;
    border-bottom: 3px solid #9FC6D1;
    color: #777;
}

.top-pane {
    background-color: white;
    margin: 0 0 3px;
    padding: 20px 20px 30px;
}

.top-pane img {
    max-width: 215px;
    max-height: 215px;
    width: auto;
    height: auto;
    /* border-radius: 50%; */
    margin-bottom: 0px;
    border: 4px solid #FEBE48;
}

.top-pane h1 {
    font-size: 36px;
    margin-bottom: 10px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: lighter;
}

.top-pane p {
    font-size: 16px;
    color: #777;
    margin-bottom: 0px;
}

.profile-pic {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.profile-pic:hover {
    transform: scale(1.05);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 15vh;
    z-index: 1000;
}

.modal-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1010;
}

.modal-image {
    max-width: 70vw;
    max-height: 70vh;
    width: auto;
    height: auto;
    border: 4px solid white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.modal:hover {
    cursor: pointer;
}

.pane {
    background-color: white;
    margin: 0 0 3px;
    padding: 2px 20px 20px;
}

.pane h1 {
    font-size: 24px;
    margin-bottom: 20px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    /* text-decoration: underline; */
}

.pane p {
    font-size: 15px;
    color: #777;
    margin-bottom: 10px;
    text-align: left;
}

.pane-heading {
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-weight: lighter;
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
    text-align: center;
}

.social-links {
    margin-top: 10px;
}

.social-links a {
    display: inline-block;
    margin-right: 10px;
    font-size: 16px;
    color: #333;
    text-decoration: none;
}

.social-links a:hover {
    color: #007bff;
}

.footer p {
    color: #5C5C5C;
    opacity: 0.7;
}

.page-links {
    list-style-type: square;
    color: #535965;
    padding: 0;
    margin: 20px 25px 0;
    text-align: left;
}

.page-links li::marker {
    font-size: 15px;
}

.page-links li {
    margin-bottom: 10px;
}

.page-links a {
    text-decoration: underline;
    color: #656C7B;
    font-size: 14px;
    transition: color 0.3s ease;
}

.page-links a:hover {
    color: #0056b3;
}